import React, {
	useEffect,
	useState,
} from "react";
import "./ProductView.scss";
import {
	Box,
	Grid,
	TextField,
	Button,
	Paper,
	Typography,
	MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SellerDependentView from "../Shared/SellerDependentView";
import { GetModalValuesFromBulkResponse } from "../../utils/translateBulkResponse";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import InventoryTable from "./components/InventoryTable";
import { DataContext } from "../../App";
import Modal from "../Shared/Modals/Modal";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import { AlertBox } from "../Shared/Alert/Alert";
import SearchFilter from "../Shared/SearchFilter/SearchFilter";
import { Link as RouterLink } from "react-router-dom";
import {
	productStatusPending,
	productStatusRejected
} from "../../utils/statusColors";
import { getIdByValue } from "../../utils/object";
import { getCSVFileFromJSONArray } from "../../utils/csv";
import { getDisplayDate } from "../../utils/date";
import { sanitizeFilterInput } from "../../utils/text";

export default function ProductView({ seller, userdata }) {

	if (!seller || !seller.id || !userdata) return null;

	const context = React.useContext(DataContext);
	const [state, setState] = useState({
		filter: "",
		statusID: 0,
		selectedWarehouse: "",
		fullInventory: [],
		filteredInventory: [],
		productStatuses: {},
		warehouses: [],
		selectedBulkAction: "",
		selectedProducts: [],
		reloadInventory: false,
		isLoading: false,
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage1List: "",
		modalBodyMessage2: "",
		modalBodyInputFields: {},
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonColor1: "primary",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
	});

	const userHasPermissionViewBulkActions = userdata.permissions && userdata.permissions.has("view_bulk_actions");
	const userHasPermissionHideSKUs = userdata.permissions && userdata.permissions.has("bulk_operation_hide_skus");
	const userHasPermissionUpdateProductStatus = userdata.permissions && userdata.permissions.has("update_product_status");
	const userHasCreateProductPermission = userdata.permissions && userdata.permissions.has("create_product");
	
	useEffect(() => {
		const getProductStatuses = async () => {
			try {
				const response = await context.dataProvider.getProductStatuses();
				setState(previousState => ({
					...previousState,
					productStatuses: response.data,
				}));
			} catch (error) {
				setState(previousState => ({
					...previousState,
					productStatuses: {},
				}));
			}
		};
		getProductStatuses();
	}, []);

	useEffect(() => {
		const getInventoryData = async () => {
			try {
				const warehouseID = state.selectedWarehouse === "" || state.selectedWarehouse === "all" ? 0 : state.selectedWarehouse;
		
				const [responseBundles, responseStock] = await Promise.all([
					context.dataProvider.getAllBundles(seller.id),
					context.dataProvider.getStock(seller.id, warehouseID, userHasPermissionHideSKUs, state.statusID)
				]);
		
				const bundlesMap = new Map();
				responseBundles.data.forEach(bundle => bundlesMap.set(bundle.sku, bundle.components));
		
				const fullInventory = responseStock.data.map(product => ({
					...product,
					bundle_components: bundlesMap.get(product.sku) || []
				}));
		
				const selectedProducts = fullInventory
					.filter((product) => product.is_hidden_for_seller === true)
					.map((product) => product.sku);
		
				setState(previousState => ({
					...previousState,
					fullInventory,
					filteredInventory: fullInventory,
					selectedProducts,
				}));
			} catch (error) {
				setState(previousState => ({
					...previousState,
					fullInventory: [],
					filteredInventory: [],
					selectedProducts: [],
				}));
			}

			setState(previousState => ({
				...previousState,
				isLoading: false,
			}));
		};

		const getSellerWarehouses = async () => {
			setState(previousState => ({
				...previousState,
				isLoading: true,
				warehouses: [],
				fullInventory: [],
				filteredInventory: [],
			}));
	
			try {
				const warehousesResponse = await context.dataProvider.getSellerWarehouses(seller.id);
				setState(previousState => ({
					...previousState,
					warehouses: warehousesResponse.data,
				}));
				await getInventoryData();
			} catch (error) {
				setState(previousState => ({
					...previousState,
					warehouses: [],
					isLoading: false,
				}));
			}
		};

		getSellerWarehouses();

	}, [context.dataProvider, seller.id, state.selectedWarehouse, state.statusID, state.reloadInventory, userHasPermissionHideSKUs]);
	
	const onChangeSelectedProducts = (products) => {
		setState(previousState => {
			return {
				...previousState,
				selectedProducts: products
			};
		});
	};

	const SelectWarehouse = ({
		...otherProps
	}) => {

		const onChangeWarehouseSelect = e => {
			const selected = e.target.value;
			setState(previousState => ({
				...previousState,
				selectedWarehouse: selected,
			}));
		};

		const configSelect = {
			...otherProps,
			select: true,
			variant: "outlined",
			onChange: onChangeWarehouseSelect
		};

		return (
			<TextField
				sx={{ minWidth: 150, marginRight: "2rem", marginTop: "0" }}
				label="Warehouse"
				name="warehouse"
				size="small"
				margin="normal"
				value={state.selectedWarehouse}
				{...configSelect}>
				<MenuItem value="all">
					All
				</MenuItem>
				{state.warehouses.map(({ id, name }) => (
					<MenuItem key={id} value={id}>
						{name}
					</MenuItem>
				))}
			</TextField>
		);
	};

	useEffect(() => {
		const search = () => {
			if (state.fullInventory.length === 0) return;

			const input = state.filter.trim();
			if (input === "") {
				setState(previousState => ({
					...previousState,
					filteredInventory: state.fullInventory,
					isLoading: false
				}));
				return;
			}

			const inputWords =  sanitizeFilterInput(input);

			const visible = state.fullInventory.filter(item => {
				return inputWords.every(word =>
					item.name.toLowerCase().includes(word) ||
					item.sku.toLowerCase().includes(word)
				);
			});

			setState(previousState => ({
				...previousState,
				filteredInventory: visible,
				isLoading: false
			}));
		};
		search();
	}, [state.filter, state.fullInventory]);

	const onClickDownloadProductsAwaitingVerification = async () => {
		const statusID = getIdByValue(state.productStatuses, "awaitingverification");

		const formattedDate = getDisplayDate(Date.now(), "", "-");
		const filename = seller.code+" Products Awaiting Verification "+formattedDate+".csv";

		context.dataProvider.getAllProducts(seller.id, statusID)
			.then((res) => {
				if (res.data && res.data.length > 0) {
					getCSVFileFromJSONArray(res.data, filename);
				}
			});
	};

	const onChangeSelectedBulkAction = (e) => {
		const bulkAction = e.target.value;

		if (bulkAction) {
			let statusID = 0;
			if (bulkAction === "approve-skus") {
				statusID = getIdByValue(state.productStatuses, "awaitingverification");
			}

			setState(previousState => ({
				...previousState,
				selectedBulkAction: e.target.value,
				isLoaded: true,
				statusID: statusID,
			}));
		}
	};

	const resetBulkActionSelection = () => {
		const selectedProducts = state.filteredInventory
			.filter((product) => product.is_hidden_for_seller === true)
			.map((product) => product.sku);

		setState(previousState => ({
			...previousState,
			isLoaded: true,
			selectedProducts: selectedProducts,
			selectedBulkAction: "",
			isModalOpen: false,
			statusID: 0,
		}));
	};

	const showHideSKUsModal = () => {
		setState(previousState => ({
			...previousState,
			modalBodyTitle: "Bulk Action: Hide SKUs",
			modalBodyMessage1: "Please confirm you would like to hide the selected SKUs.",
			modalBodyMessage2: "",
			modalButtonVariant1: "contained",
			modalButtonText1: "No",
			modalButtonActionType1: "close",
			modalButtonAction1: "",
			modalBodyMessage1Data: [],
			modalButtonVariant2: "contained",
			modalButtonText2: "Yes, hide selected SKUs",
			modalButtonActionType2: "function",
			modalButtonAction2: onClickHideSKUs,
			modalButtonColor2: "destructive",
			isModalOpen: true,
		}));
	};

	const showUpdateProductStatusModal = (action) => {
		let status = productStatusPending;
		let inputFields = {};
		if (action === "reject") {
			status = productStatusRejected;

			inputFields = {
				"rejection_comment": {
					type: "string",
					label: "Comment",
				},
			};
		}

		setState(previousState => ({
			...previousState,
			modalBodyTitle: "Bulk Action: Approve SKUs",
			modalBodyMessage1: `Please confirm you would like to ${action} the selected SKUs.`,
			modalBodyMessage2: "",
			modalButtonVariant1: "contained",
			modalButtonText1: "Cancel",
			modalButtonActionType1: "close",
			modalButtonAction1: "",
			modalButtonColor1: "destructive",
			modalBodyMessage1Data: [],
			modalBodyInputFields: inputFields,
			modalButtonVariant2: "contained",
			modalButtonText2: `Yes, ${action} selected SKUs`,
			modalButtonActionType2: "function",
			modalButtonAction2: (values) => onClickUpdateProductStatus(values, status),
			isModalOpen: true,
		}));
	};

	const onClickHideSKUs = async () => {
		const options = {
			operationName: "hide products",
			successfulOperation: "Hidden",
			success: "Products successfully hidden",
			onClickCloseButton: resetBulkActionSelection,
		};

		let res;
		try {
			res = await context.dataProvider.hideProducts(seller.id, state.selectedProducts);
		} catch (error) {
			res = error.response;
		}

		const newState = GetModalValuesFromBulkResponse(res, options);
		setState(previousState => ({
			...previousState,
			...newState,
			reloadInventory: !state.reloadInventory,
		}));
	};

	const onClickUpdateProductStatus = async (values, status) => {
		const statusID = getIdByValue(state.productStatuses, status);
		const comment = status === productStatusRejected ? (values.rejection_comment || "") : "";

		const productStatuses = {
			status_id: Number(statusID),
			skus: state.selectedProducts,
			comment: comment,
		};

		let res;
		try {
			res = await context.dataProvider.updateProductStatuses(seller.id, productStatuses);
		} catch (error) {
			res = error.response;
		}

		const options = {
			operationName: "update product status",
			successfulOperation: "Statuses Updated",
			success: "Product statuses successfully updated",
			onClickCloseButton: resetBulkActionSelection,
		};

		const newState = GetModalValuesFromBulkResponse(res, options);
		setState(previousState => ({
			...previousState,
			...newState,
			statusID: 0,
		}));
	};

	const onModalClose = (props) => {
		resetBulkActionSelection();

		setState(previousState => ({
			...previousState,
			isModalOpen: props,
		}));
	};

	const handleFilterChange = (value) => {
		setState(previousState => {
			return {
				...previousState,
				filter: value,
			};
		});
	};

	return (
		<Box id="product-view">
			<SellerDependentView seller={seller.id}>
				<Box>
					<Grid container spacing={1}>
						{userHasCreateProductPermission &&
							<>
								<Grid item xs="auto">
									<Button
										variant="contained"
										component={RouterLink}
										to={"/product-create"}
									>
										Create a product<AddIcon/>
									</Button>
								</Grid>
								<Grid item xs="auto">
									<Button
										color="secondary"
										variant="outlined"
										component={RouterLink}
										to="/inventory-upload"
									>
										Import Products<AddIcon/>
									</Button>
								</Grid>
							</>
						}
						<Grid item xs/>
					</Grid>
					
					<Paper sx={{ p: 2, width: "100%", mt: 3 }} elevation={2}>
						<Typography variant="h5">
							Inventory <RecordsTotal total={state.filteredInventory.length}/>
						</Typography>

						<Grid item container className="form-inputs">

							{userHasPermissionViewBulkActions && 
								<Grid item xs="auto">
									<TextField
										select
										fullWidth
										size="small"
										sx={{ minWidth: "200px", width: "auto", paddingRight: "1rem", mb: 2 }}
										id="bulk-actions-select"
										inputProps={{ id: "bulk-actions-select", "data-testid": "bulk-actions-select" }}
										label="Bulk Actions"
										value={state.selectedBulkAction}
										onChange={(e) => onChangeSelectedBulkAction(e)}
									>
										{userHasPermissionHideSKUs &&
											<MenuItem value={"hide-skus"}>
												Hide SKUs
											</MenuItem>
										}
										{userHasPermissionUpdateProductStatus &&
											<MenuItem value={"approve-skus"}>
												Approve SKUs
											</MenuItem>
										}
									</TextField>
								</Grid>
							}

							<Grid item xs/>

							{state.warehouses.length > 1 &&
								<Grid item xs="auto" mb={1}>
									<SelectWarehouse/>
								</Grid>
							}

							<Grid item xs="auto">
								<SearchFilter
									filter={state.filter}
									onFilterChange={handleFilterChange}
									label={"Search"}
								/>
							</Grid>

						</Grid>

						{userHasPermissionViewBulkActions && state.selectedBulkAction &&
							<Grid
								item container
								className="form-inputs">
								<Grid item xs="auto">
									{state.selectedBulkAction === "hide-skus" &&
										<AlertBox
											severity="info"
											message="Please select the SKUs you would like to hide."
										/>
									}
									{state.selectedBulkAction === "approve-skus" &&
										<AlertBox
											severity="info"
											message="Please select the SKUs you would like to approve/reject."
										/>
									}
								</Grid>

								<Grid item xs="auto">
									{state.selectedBulkAction === "hide-skus" &&
										<Button
											variant="contained"
											sx={{ mr: 1, mb: 1 }}
											className='button-confirm'
											onClick={showHideSKUsModal}
										>
											Confirm
										</Button>
									}
									{state.selectedBulkAction === "approve-skus" &&
										<>
											<Button
												variant="outlined"
												sx={{ mb: 1, mr: 1 }}
												className='button-confirm'
												onClick={() => onClickDownloadProductsAwaitingVerification()}
											>
												Export Products
											</Button>
											<Button
												variant="contained"
												sx={{ mb: 1, mr: 1 }}
												className='button-confirm'
												onClick={() => showUpdateProductStatusModal("approve")}
											>
												Approve
											</Button>
											<Button
												variant="contained"
												sx={{ mb: 1, mr: 1 }}
												className='button-confirm'
												color="destructive"
												onClick={() => showUpdateProductStatusModal("reject")}
											>
												Reject
											</Button>
										</>
									}
									<Button
										variant="contained"
										sx={{ mb: 1 }}
										color="destructive"
										className='button-cancel'
										onClick={resetBulkActionSelection}
									>
										Cancel
									</Button>
								</Grid>

							</Grid>
						}

						<LoadableContent isLoading={state.isLoading}>
							<InventoryTable
								inventory={state.filteredInventory}
								warehouses={state.warehouses}
								isVisibleBulkAction={state.selectedBulkAction}
								isVisibleIsHiddenForSeller={userHasPermissionHideSKUs}
								onChangeSelectedProducts={onChangeSelectedProducts}
								selectedProducts={state.selectedProducts}
								productStatuses={state.productStatuses}
							/>
							<Modal
								onModalClose={onModalClose}
								isModalOpen={state.isModalOpen}
								modalBodyTitle={state.modalBodyTitle}
								modalBodyMessage1={state.modalBodyMessage1}
								modalBodyMessage1Data={state.modalBodyMessage1Data}
								modalBodyMessage1List={state.modalBodyMessage1List}
								modalBodyMessage2={state.modalBodyMessage2}
								modalBodyInputFields={state.modalBodyInputFields}
								modalButtonVariant1={state.modalButtonVariant1}
								modalButtonText1={state.modalButtonText1}
								modalButtonActionType1={state.modalButtonActionType1}
								modalButtonAction1={state.modalButtonAction1}
								modalButtonColor1={state.modalButtonColor1}
								modalButtonVariant2={state.modalButtonVariant2}
								modalButtonText2={state.modalButtonText2}
								modalButtonActionType2={state.modalButtonActionType2}
								modalButtonAction2={state.modalButtonAction2}
								modalButtonColor2={state.modalButtonColor2}
							/>
						</LoadableContent>
					</Paper>
				</Box>
			</SellerDependentView>
		</Box>
	);
}
